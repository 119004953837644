import Routes from '../../routes/routes'
import { buildClientError, buildPostApi } from '../helpers'
import buildOptions from '../buildApiOptions'
import { buildOrder } from './converters'
import { MaxCouponsExceededError } from '../errors'
import { MAX_COUPONS_EXCEEDED_ERROR } from '../constants'
function buildError (error) {
    if (error instanceof MaxCouponsExceededError) {
        const data = { ...error.response }
        return {
            name: MAX_COUPONS_EXCEEDED_ERROR,
            status: 462,
            data,
        }
    }

    return buildClientError(error)
}
export default buildPostApi({
    route: Routes.apiMerchantOrder,
    buildResult: buildOrder,
    mapPayload: (order) => ({ lines: order.lines.map(({ quantity, price }) => ({
        num_coupons: quantity,
        coupon_value: price,
    }))}),
    buildOptions,
    buildError,
})
